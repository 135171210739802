@import 'settings.css';

.breadcrumbs {
  margin-bottom: 4rem;
}

.breadcrumbs-label {
  height: 0;
  overflow: hidden;
  margin: 0;
  text-indent: 100%;
  white-space: nowrap;
}

.breadcrumbs-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.breadcrumbs-list-item {
  display: inline-block;
}

.breadcrumbs-list-item-link {
  font-weight: bold;
  color: var(--tigerOrange);
}

.breadcrumbs-list-item-link:--focus-hover {
  color: var(--tigerOrangeHover);
}

.breadcrumbs-list-item:not(:first-child)::before {
  content: '\00BB';
  margin: 0 0.8rem;
}

@media (max-width: 400px) {
  .breadcrumbs-list-item {
    display: block;
  }

  .breadcrumbs-list-item:not(:first-child)::before {
    margin: 0;
  }
}
