.cookie-value {
  padding: 2px 11px;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #545454;
  background: #ececec;
}

.cookie-value + .cookie-value {
  margin-left: 10px;
}

.cookie-info + .cookie-info {
  margin-top: 30px;
}

.cookie-info.mod-small + .cookie-info.mod-small {
  margin-top: 10px;
}

.cookie-section-information-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cookie-header {
  padding: 0;
  margin: 0 0 14px;
  font-size: 27px;
  font-weight: bold;
  color: #333;
}

.cookie-text {
  font-size: 16px;
  line-height: 1.5;
}

.cookie-form-element {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.cookie-form-input {
  margin: 0;
}

.cookie-form-label-text {
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
}

.cookie-section-information-header-button {
  width: 22px;
  height: 22px;
  padding: 0;
  border: none;
  background: transparent url(../images/cross.svg) center / contain no-repeat;
  cursor: pointer;
}

.cookie-info-header {
  margin: 0;
  font-size: 20px;
}

.cookie-info-link {
  display: inline-block;
  margin: 3px 0 8px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: #0c689e;
}

.cookie-section-information-heading {
  margin: 24px 0;
  font-size: 24px;
  color: #333;
}

.cookie-section-heading {
  margin-bottom: 10px;
  font-size: 20px;
}

.cookie-section-information {
  display: none;
  height: 0;
  overflow: hidden;
  border-top: 1px solid #000;
  margin-top: 15px;
  transition: height 650ms ease-in-out;
}

.cookie-section-information.is-visible {
  display: block;
  height: auto;
}

.cookie-button {
  padding: 10px 24px;
  border: 1px solid;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.cookie-button.mod-primary {
  border-color: #3e3e3e;
  color: #fff !important;
  background-color: #3e3e3e;
}

.cookie-button.mod-tertiary {
  border-color: #808080;
  color: #000;
  background-color: transparent;
}

.cookie-button.mod-primary:hover {
  border-color: #545454;
  background-color: #545454;
}

.cookie-button.mod-tertiary:hover {
  border-color: #4e4e4e;
  color: #4e4e4e;
}

.cookie-button:disabled {
  background-color: #b3b3b3;
  cursor: auto;
}

.cookie-button-inline {
  padding: 0;
  border: none;
  font-size: inherit;
  font-weight: bold;
  background: none;
  cursor: pointer;
}

.cookie-button-inline:--focus-hover {
  color: #5d5d5d;
}

.cookie-info-values {
  display: flex;
  flex-wrap: wrap;
}

.cookie-info-container {
  margin-bottom: 5px;
}

.cookie-info-description {
  font-size: 14px;
  line-height: 1.5;
}

.cookie-section + .cookie-section {
  margin-top: 30px;
}

.cookie-section-settings {
  display: none;
  height: 0;
  overflow: hidden;
  margin-top: 15px;
  transition: height 350ms ease-in-out;
}

.cookie-section-settings.is-visible {
  display: block;
  height: auto;
}

/* NEW STUFF */
:root {
  --cookieBorder: 1px solid #d9d9d9;
}

.cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 -2px 4px rgb(208 208 208 / 59%);
  text-align: center;
  color: #000;
  background: #fff;
  transition: 0.4s transform ease;
  transform: translateY(0);
}

.cookie-form {
  max-width: 800px;
}

.cookie-simple-form {
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

@media (width < 700px) {
  .cookie-simple-form {
    flex-direction: column;
  }
}

.cookie-notice-heading {
  font-weight: bold;
  color: #000;
  font-size: 22.5px;
  margin: 0;
  padding: 0;
}

.cookie-notice-text {
  font-size: 15px;
  margin: 15px 0;
  line-height: 1.55;
}

.cookie-notice-button-container {
  display: flex;
  flex-direction: column;
}

@media (width >= 700px) {
  .cookie-notice-button-container {
    margin-left: 30px;
  }
}

.cookie-notice-button {
  width: 140px;
  padding: 10px 26px;
  border: none;
  border-radius: 4px;
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  background: #000;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.cookie-notice-button:--focus-hover {
  background: #1a1a1a;
}

.cookie-notice-button.mod-secondary {
  border: 1px solid black;
  background: #fff;
  color: #000;
}

.cookie-notice-button + .cookie-notice-button {
  margin-top: 8px;
}

.cookie-notice-button:focus {
  box-shadow: 0 0 0 3px #9a9a9a;
}

/** END COOKIE NOTICE */

.cookie-detail {
  /* border-top: var(--cookieBorder);
  border-bottom: var(--cookieBorder); */
  margin-top: 30px;
}

.cookie-detail-section {
  padding: 18px 0;
}

.cookie-detail-input-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cookie-detail-input-text {
  margin-right: 14px;
  font-size: 14px;
  font-weight: bold;
}

.cookie-detail-input-text.mod-disabled {
  color: grey !important;
}

.cookie-detail-input-text.mod-enabled {
  color: #49a722 !important;
}

.cookie-detail-section + .cookie-detail-section {
  border-top: var(--cookieBorder);
}

.cookie-detail-section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.cookie-detail-section-heading {
  margin: 0 0 3px;
  color: #000;
}

@media (width <= 540px) {
  .cookie-detail-section-head {
    flex-direction: column;
  }

  .cookie-detail-section-heading {
    margin: 0 0 10px;
  }
}

.cookie-detail-section-text {
  margin: 5px 0 0;
  font-size: 14.5px;
  line-height: 1.5;
}

.cookie-detail-buttons {
  display: flex;
  justify-content: flex-end;
}

@media (width <= 540px) {
  .cookie-detail-buttons {
    flex-direction: column;
  }
}

.cookie-button {
  transition: box-shadow 0.3s ease;
  outline: none;
}

.cookie-button.mod-primary {
  background-color: #000;
}

.cookie-button:focus {
  box-shadow: 0 0 0 3px #9a9a9a;
}

@media (width > 540px) {
  .cookie-button + .cookie-button {
    margin-left: 15px;
  }
}

/* FANCY CHECKBOX */

.cookie-detail-label {
  display: block;
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 100px;
  text-indent: -70px;
  background: #808080;
  cursor: pointer;
  transform: box-shadow;
}

.focus-visible.cookie-detail-input:focus:checked + .cookie-detail-label {
  box-shadow: 0 0 0 3px #a1e884;
}

.focus-visible.cookie-detail-input:focus + .cookie-detail-label {
  box-shadow: 0 0 0 3px #d6d6d6;
}

.cookie-detail-input:disabled + .cookie-detail-label {
  opacity: 0.3;
  cursor: not-allowed;
}

.cookie-detail-label::after {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 19px;
  height: 19px;
  content: "";
  border-radius: 90px;
  background: #fff;
  transition: 0.3s;
}

.cookie-detail-input:checked + .cookie-detail-label {
  background: #5cc92f;
}

.cookie-detail-input:checked + .cookie-detail-label::after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

.cookie-detail-input:active::after {
  width: 25px;
}
