@import 'settings.css';

.app-shell-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-shell-content-container {
  flex: 1 0 auto;
}

.app-shell-main {
  display: block;
  max-width: var(--containerWidth);
  padding: 2rem 2rem 7rem;
  margin: var(--containerMargin);
  box-shadow: 0 0 4px #e4e4e4;
  background-color: #fff;
}

.app-shell-heading {
  margin: 1rem 0 1.7rem;
  font-size: 3.5rem;
  letter-spacing: -0.1rem;
  line-height: 1.3;
  color: var(--tigerOrange);
}

.app-shell-skip-link {
  position: absolute;
  top: -1000px;
  left: -1000px;
  width: 0.1rem;
  height: 0.1rem;
  overflow: hidden;
  padding: 1rem;
  text-align: left;
  color: var(--tigerOrange);
  background: #fff;
}

.app-shell-skip-link:active,
.app-shell-skip-link:--focus-hover {
  top: 0;
  z-index: 6;
  width: auto;
  height: auto;
  overflow: visible;
}

.app-shell-wide {
  display: block;
  max-width: var(--containerWidthWide);
  padding: 2rem 2rem 7rem;
  margin: var(--containerMarginWide);
  box-shadow: 0 0 4px #e4e4e4;
  background-color: #fff;
}

@media (max-width: 800px) {
  .app-shell-skip-link:active,
  .app-shell-skip-link:--focus-hover {
    left: 50%;
    border-radius: 0 0 0.6rem 0.6rem;
    transform: translateX(-50%);
  }
}

@media (min-width: 801px) {
  .app-shell-skip-link:active,
  .app-shell-skip-link:--focus-hover {
    left: 0;
    border-radius: 0 0 0.6rem;
  }
}

.app-shell-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .app-shell-heading-container {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
}

/* IE11 Deprecation Notice */
.warning-banner {
  background: #ffe879;
  padding: 1.5rem 0;
}

.warning-banner-inner {
  max-width: var(--containerWidth);
  margin: 0 auto;
}

.warning-banner-text {
  line-height: 1.5;
  margin: 0;
}

