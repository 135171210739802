.create-issue-dropdown {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.create-issue-dropdown-item {
  width: 22.5rem;
  text-align: center;
}

.create-issue-dropdown-item-link {
  display: block;
  padding: 2rem;
  transition: transform 0.2s, background 0.2s;
}

.create-issue-dropdown-item-link:--focus-hover {
  border-radius: 0.4rem;
  background: #f3f3f3;
}

.create-issue-dropdown-image {
  position: relative;
  width: 7rem;
  height: 6rem;
  margin: 0 auto 1.5rem;
  background: bottom / contain no-repeat;
  pointer-events: none;
}

.create-issue-dropdown-image.mod-from-file {
  background-image: url(../images/create/from-file.svg);
}

.create-issue-dropdown-image.mod-from-design {
  background-image: url(../images/create/from-design.svg);
}

.create-issue-dropdown-heading {
  display: block;
  margin-bottom: 0.7rem;
  font-size: 2rem;
  font-weight: bold;
  color: #3c3c3c;
  pointer-events: none;
}

.create-issue-dropdown-text {
  color: #7b7b7b;
  pointer-events: none;
}
