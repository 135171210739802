@custom-selector :--focus-hover :focus, :hover;

:root {
  --containerWidth: 130rem;
  --containerWidthWide: 180rem;
  --containerMargin: 0 auto;
  --containerMarginWide: 0 auto;
  --containerPadding: 0 2rem;
  --adminDevColor: #026ab7;
  --adminProductionColor: #026ab7;
  --adminStagingColor: #087b56;
  --borderRadius: 0.4rem;
  --boxShadow: 0 0.1rem 0.3rem #dedede;
  --border: 0.1rem solid #e6e6e6;
  --borderDark: 0.1rem solid #c3c3c3;
  --black: #4d4d4d;
  --tigerBlack: hsl(0 0% 10%);
  --errorColour: #b50400;
  --tigerOrange: #ef8023;
  --tigerOrangeHover: #da6b10;
  --tigerGrey: #76716c;
  --tigerGreyHover: #5f5c58;
  --tigerRed: #c46761;
  --tigerBlue: #109fff;
  --tigerBlueHover: #0081d6;
  --pageTigerOnlyBG: #efe7c0;
  --warningBGColour: #fff3cd;
  --warningTextColour: #664d03;
  --warningBorderColour: #d7c182;

  /* Drag and Drop list styles */
  /* Container settings */
  --list-select-padding: 0.8rem;
  --list-select-border: var(--borderDark);         /* Uses your existing --borderDark */
  --list-select-border-radius: var(--borderRadius);  /* Uses your existing --borderRadius */
  --list-select-background: #fff;
  --list-select-min-height: 10rem;
  --list-select-height: fit-content;

  /* List item settings */
  --list-select-item-padding: 10px;
  --list-select-item-border: 1px solid #ccc;
  --list-select-item-cursor: grab;
  --list-select-item-border-radius: 4px;
  --list-select-item-margin: 2px 0;

  /* State: selected */
  --list-select-item-selected-bg: #d0eaff;

  /* State: dragging */
  --list-select-item-dragging-opacity: 0.5;

  /* State: hover */
  --list-select-item-hover-bg: #f5f5f5;

  /* ::before pseudo-element for list items */
  --list-select-item-before-color: #888;
  --list-select-item-before-font-size: 14px;
  --list-select-item-before-margin-right: 1rem;

  /* Remove button */
  --list-remove-button-margin-top: 2rem;

  /* Keyboard active/focus styles */
  --list-select-keyboard-outline: 2px dashed #4095ef;
  --list-select-keyboard-active-bg: #eef6ff;

}
