@import 'settings.css';

:root {
  --tertiaryBackgroundColour: #fff;
}

.button {
  padding: 0.8rem 1.5rem;
  border-radius: 0.3rem;
  font: inherit;
  font-size: 1.5rem;
  cursor: pointer;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  max-height: 3.6rem;
}

.button.is-full {
  width: 100%;
}

.primary-button,
.secondary-button,
.tertiary-button,
.blue-button,
.new-button,
.delete-button {
  border: none;
  color: #fff;
}

.primary-button {
  background-color: var(--tigerOrange);
}

.primary-button:--focus-hover {
  color: #fff;
  background-color: var(--tigerOrangeHover);
}

.delete-button {
  background-color: #d02020;
}

.delete-button:--focus-hover {
  background-color: #f33d3d;
}

.new-button {
  background-color: #17ab03;
}

.new-button:--focus-hover {
  color: #fff;
  background-color: #33c11f;
}

.blue-button {
  background-color: #069;
}

.blue-button:--focus-hover {
  background-color: #148ecc;
}

.secondary-button {
  border: none;
  color: #fff;
  background-color: var(--tigerGrey);
}

.secondary-button:--focus-hover {
  background-color: var(--tigerGreyHover);
}

.tertiary-button {
  border: 0.1rem solid #adadad;
  color: #333;
  background-color: var(--tertiaryBackgroundColour);
}

.tertiary-button,
.tertiary-link-style-button {
  color: #333;
}

.link-button {
  border: none;
  text-decoration: none;
  color: #2e78c1;
  background-color: none;
  cursor: pointer;
}

.tertiary-button:--focus-hover {
  background-color: var(--tertiaryBackgroundColour);
}

.primary-button[aria-disabled='true'],
.primary-button:disabled,
.primary-button:disabled:--focus-hover,
.secondary-button[aria-disabled='true'],
.secondary-button:disabled,
.secondary-button:disabled:--focus-hover,
.new-button[aria-disabled='true'],
.new-button:disabled,
.new-button:disabled:--focus-hover {
  border-color: #d4d4d4;
  color: #a7a7a7;
  background-color: #eaeaea;
  cursor: default;
}

.tertiary-link-style-button {
  padding: 0.8rem 0.5rem;
  border: none;
  background: none;
  cursor: pointer;
}

.tertiary-link-style-button:--focus-hover {
  color: #a6a6a6;
}

.primary-button.is-icon,
.secondary-button.is-icon,
.tertiary-button.is-icon {
  width: 4.2rem;
  height: 3.4rem;
  overflow: hidden;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
}

.copy-button {
  flex-shrink: 0;
  width: 4.2rem;
  height: 3.4rem;
  overflow: hidden;
  padding: 0;
  border: none;
  text-indent: 100%;
  white-space: nowrap;
  background: url(../images/copy.svg) center / 26px no-repeat;
}

.copy-button.mod-small {
  width: 2.4rem;
  height: 2.4rem;
  background-size: 22px;
}

.mod-stacked {
  width: 100%;
  white-space: nowrap;
}

.mod-stacked + .mod-stacked {
  margin-top: 1rem;
}

.mod-reorder {
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  background: #9a9a9a url(../images/email/scroll.svg) center / 22px no-repeat;
  cursor: grab;
}

.mod-reorder:--focus-hover {
  background-color: #636363;
  transition: 0.3s background ease-in-out;
}

.mod-delete-colour {
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  background: #fff url(../images/email/rubbish-bin.svg#black) center / 22px no-repeat;
}

.mod-reorder:active {
  cursor: grabbing;
}

.button.mod-link {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.mod-bold {
  font-weight: bold;
}
