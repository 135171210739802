.visually-hidden {
  position: absolute;
  top: auto;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.is-hidden {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

.is-shown {
  display: block;
}

.hide-text {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.hide-inline {
  display: inline-block;
  width: 0;
  height: 0;
}

.dot {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0 0.2rem;
  background-color: #000;
}

.dot.mod-large {
  width: 2rem;
  height: 2rem;
}

.dot.mod-green {
  background-color: #33c11f;
}

.dot.mod-amber {
  background-color: var(--tigerOrange);
}

.dot.mod-red {
  background-color: #d02020;
}

.dot.mod-light {
  background-color: #ccc;
}

.dot-label {
  padding: 0 0.5rem;
  margin: 0 0.2rem;
  color: #fff;
  background-color: #ccc;
}

.dot-label.mod-green {
  background-color: #33c11f;
}

.dot-label.mod-amber {
  background-color: var(--tigerOrange);
}

.dot-label.mod-red {
  background-color: #d02020;
}

.dot-label.mod-light {
  background-color: #ccc;
}

.tox-tinymce {
  border-radius: 0 !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  box-shadow: none !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  border-bottom: 1px solid #e0e0e0 !important;
}