@import 'settings.css';

.header {
  z-index: 2;
  background: var(--tigerBlack);
}

.header.is-admin-dev {
  background: var(--adminDevColor);
}
.header.is-admin-staging {
  background: var(--adminStagingColor);
}

.header.is-admin-production {
  background: var(--adminProductionColor);
}

.header-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 130rem;
  padding: 0 2rem;
  margin: 0 auto;
}

.header-inner-container-logo-link {
  flex-shrink: 0;
  width: 16rem;
  height: 7rem;
  padding: 1rem 0;
  background: url(../images/page-tiger-logo-white.svg) center / 80% no-repeat;
}

.header.mod-wide .header-inner-container {
  max-width: var(--containerWidthWide);
}