@import 'settings';

.nav-menu,
.nav-pop-up-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

@media (max-width: 1000px) {
  .nav {
    display: none;
    flex-direction: column;
    position: static;
    top: 100%;
    left: 0;
    width: 100%;
    border-bottom: 0.1rem solid #5d5d5d;
    background: #373737;
  }

  .nav.is-admin-dev {
    background-color: var(--adminDevColor);
  }

  .nav.is-admin-staging {
    background-color: var(--adminStagingColor);
  }

  .nav.is-admin-production {
    background-color: var(--adminProductionColor);
  }

  .nav-pop-up-list {
    padding-bottom: 1.6rem;
  }
}

@media (min-width: 1001px) {
  .nav-menu {
    display: flex;
    align-items: center;
  }

  .nav-item {
    display: inline-block;
    position: relative;
  }

  .nav-item:not(:first-child) {
    margin-left: 3.5rem;
  }
}

@media (width > 1000px) and (width < 1200px) {
  .nav-item:not(:first-child) {
    margin-left: 2.5rem;
  }
}

@media (max-width: 1000px) {
  .nav-item {
    border-top: 0.1rem solid #5d5d5d;
  }

  .is-admin .nav-item {
    border-top-color: #005290;
  }
}

.nav-item.mod-intermediate {
  display: none;
}

@media (width > 700px) and (width < 1400px) {
  .nav-item.mod-intermediate {
    display: block;
  }
}

.nav-item-text.mod-user-icon::before {
  display: block;
  width: 2rem;
  height: 2rem;
  content: '';
  margin-right: 0.7rem;
  background: url(../images/user.svg#white) center / contain no-repeat;
}

/* stylelint-disable-next-line */
.nav-item-text.mod-user-icon.open::before {
  background-image: url(../images/user.svg#orange);
}

/* stylelint-disable-next-line */
.nav-item-text.mod-notifications::after,
.nav-pop-up.open .nav-pop-up-link.mod-notifications::before {
  position: absolute;
  width: 1.9rem;
  height: 1.9rem;
  content: attr(data-notifications);
  border-radius: 50%;
  box-shadow: 0 0 5px #333;
  font-size: 1.4rem;
  text-align: center;
  color: #fff;
  background: var(--tigerOrange);
}

.nav-item-text.mod-notifications::after {
  top: -1rem;
  right: -1rem;
}

/* stylelint-disable-next-line */
.nav-pop-up.open .nav-pop-up-link.mod-notifications::before {
  top: -0.2rem;
  left: -0.5rem;
}

.nav-pop-up-link-notification {
  display: flex;
  justify-content: space-between;
}

.nav-pop-up-link-notification-bubble {
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  box-shadow: 0 0 5px #333;
  font-size: 1.4rem;
  text-align: center;
  background: var(--tigerOrange);
}

.nav-item-text {
  display: flex;
  font-size: 1.7rem;
  font-weight: bold;
  color: #fff;
}

@media (width >= 1000px) and (width < 1200px) {
  .nav-item-text {
    font-size: 1.5rem;
  }
}

.nav-item-text.open:not(.new-button),
.nav-item-text:not(.new-button):--focus-hover {
  color: var(--tigerOrange);
}

.nav-item-button {
  display: flex;
  padding-right: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.nav-item-button.new-button {
  font-weight: bold;
}

.nav-pop-up {
  display: none;
}

.nav-item:last-child .nav-pop-up {
  right: 0;
  left: auto;
}

.nav-pop-up.is-admin-dev {
  box-shadow: none;
  background: var(--adminDevColor);
}

.nav-pop-up.is-admin-staging {
  box-shadow: none;
  background: var(--adminStagingColor);
}

.nav-pop-up.is-admin-production {
  box-shadow: none;
  background: var(--adminProductionColor);
}

.nav-pop-up.mod-create-issue {
  background: #fff;
}

@media (min-width: 1001px) {
  .nav-pop-up {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 15rem;
    padding-top: 2.4rem;
    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
    box-shadow: 0 1px 5px var(--tigerBlack);
    color: #373737;
    background-color: var(--tigerBlack);
  }

  .nav-pop-up.mod-create-issue {
    top: calc(100% + 1.3rem);
    padding-top: 0;
    border-radius: 0.4rem;
    width: auto;
    box-shadow: 0 1px 6px #a0a0a0;
  }

  .nav-pop-up.mod-create-issue::before {
    display: block;
    position: absolute;
    top: -1.6rem;
    left: 6.5rem;
    width: 0;
    height: 0;
    content: '';
    border: 0.8rem solid transparent;
    border-bottom-color: #fff;
  }

  .publications-nav {
    width: 14rem;
  }

  .tigermailer-nav {
    width: 15rem;
  }

  .tigerplayer-nav {
    width: 15rem;
  }

  .my-account-nav {
    width: 18rem;
  }
}

.nav-pop-up-link {
  display: block;
  position: relative;
  padding: 1rem;
  font-weight: bold;
  color: #fff;
}

.nav-pop-up-link:--focus-hover {
  color: #fff;
  background-color: var(--tigerOrange);
}

.nav-pop-up-item:last-child .nav-pop-up-link {
  border-radius: 0 0 var(--borderRadius) var(--borderRadius);
}

@media (max-width: 1000px) {
  .nav-pop-up-link {
    text-align: center;
  }

  .nav.is-open {
    display: flex;
    position: absolute;
  }
}

/* Responsive Nav */
@media (min-width: 1001px) {
  .mobile-nav-menu {
    display: none;
  }
}

@media (max-width: 1000px) {
  .mobile-nav-menu {
    display: flex;
  }
}

.nav-menu-button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.mobile-nav-menu-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0 0.4rem;
  background: transparent;
  cursor: pointer;
}

.mobile-nav-menu-button:last-child {
  margin-right: 0;
}

.nav-menu-button-text {
  display: block;
  position: relative;
  margin-top: 0.6rem;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  text-indent: 0.1rem;
  color: #fff;
  text-transform: uppercase;
}

@media (max-width: 1000px) {
  .nav-item-icon {
    display: none;
  }
}

/* sub-navigation panel open state */
.nav-pop-up.open {
  display: block;
}

@media (max-width: 1000px) {
  .nav-item-text {
    display: flex;
    justify-content: center;
    /* stylelint-disable declaration-no-important */
    padding: 2rem !important;
  }

  .nav-item-text.mod-with-icon {
    padding: 0 !important;
  }
}
