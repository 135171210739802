/* stylelint-disable selector-max-universal, selector-max-type, property-no-vendor-prefix */
@import 'settings.css';

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  padding: 0;
  margin: 0;
  font: 1.5rem Lato, sans-serif;
  color: #525252;
  background: hsl(0 0% 97.3%);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

main {
  display: block;
  position: relative;
}

a {
  text-decoration: none;
}

button {
  font-size: 1.5rem;
}

.primary-button,
.tertiary-button {
  padding: 0.8rem 1.6rem;
  border: none;
  border-radius: var(--borderRadius);
  cursor: pointer;
}

.primary-button {
  color: #fff;
  background-color: var(--tigerOrange);
}

.primary-button:--focus-hover {
  background-color: var(--tigerOrangeHover);
}

.tertiary-button {
  color: #000;
  background: transparent;
}

.tertiary-button:--focus-hover {
  color: #808080;
}

.tertiary-check-style-button {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tertiary-check-style-button-check {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid #c3c3c3;
  margin-right: 0.8rem;
}

.tertiary-check-style-button-checked {
  background-image: url(../images/tick-green.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.tertiary-check-style-button-unchecked {
  background-image: none;
}

/* stylelint-disable declaration-no-important */
.link-button {
  padding: 0;
  border: none;
  font: inherit;
  text-decoration: none;
  color: #458ed6;
  background: none;
  cursor: pointer;
  outline: none;
}

.link-button:--focus-hover {
  color: #69b3fd;
}

.label-warning {
  margin: 1rem 0;
  font-weight: bold;
  line-height: 1.4;
}

.widget-align-right#hubspot-messages-iframe-container{
  bottom: -80px !important;
  transition: bottom 0.5s ease;
}

#support-chat-container.hide {
  bottom: -100% !important; /* Visible state */
}

#support-chat-container{
  display: initial !important;
    z-index: 2147483647;
    position: fixed !important;
    bottom: 0 !important;
    padding: 10px;
    transition: bottom 0.5s ease;
}

#support-chat-container.widget-align-right {
  right: 0 !important;
}

#support-chat-container.widget-align-left {
  left: 0 !important;
}

#hubspot-conversations-inline-iframe {
  min-height: 96px; min-width: 100px; width: 375px; height: 600px;
  border: 0px;
  box-shadow: -2px 3px 10px 5px rgb(0 0 0 / 10%);
  border-radius: 10px;
 }


html.hs-messages-widget-open.hs-messages-mobile,
html.hs-messages-widget-open.hs-messages-mobile body {
  overflow: hidden !important;
  position: relative !important;
}
html.hs-messages-widget-open.hs-messages-mobile body {
  height: 100% !important;
  margin: 0 !important;
}
#hubspot-messages-iframe-container {
  display: initial !important;
  z-index: 2147483647;
  position: fixed !important;
  bottom: 0 !important;
}
#hubspot-messages-iframe-container.widget-align-left {
  left: 0 !important;
}
#hubspot-messages-iframe-container.widget-align-right {
  right: 0 !important;
}
#hubspot-messages-iframe-container.internal {
  z-index: 1016;
}
#hubspot-messages-iframe-container.internal iframe {
  min-width: 108px;
}
#hubspot-messages-iframe-container .hs-shadow-container {
  display: initial !important;
  z-index: -1;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  content: "";
}
#hubspot-messages-iframe-container .hs-shadow-container.internal {
  display: none !important;
}
#hubspot-messages-iframe-container .hs-shadow-container.active {
  width: 400px;
  height: 400px;
}
#hubspot-messages-iframe-container iframe {
  display: initial !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  background: transparent !important;
}
.hs-drag-handle {
  position: absolute;
  bottom: 16px;
  cursor: grab;
  height: 60px;
  display: none;
  align-items: center;
}
.hs-drag-handle--right {
  right: 76px;
}
.hs-drag-handle--left {
  left: 76px;
}
.hs-drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
  z-index: 999;
  display: none;
}

.cross-button {
  width: 30px;
  height: 30px;
  background-color: #f44336;
  border: none;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
  transition: border-radius 0.3s ease;
}
.cross-button:before, .cross-button:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  background-color: white;
}
.cross-button:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.cross-button:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.cross-button:hover {
  border-radius: 50%; 
}