@import 'settings.css';

.footer {
  flex-shrink: 0;
  padding-bottom: 4rem;
  background: hsl(0 0% 10%);
}

.footer-inner-container {
  max-width: var(--containerWidth);
  padding: var(--containerPadding);
  margin: var(--containerMargin);
}

.footer-link-container {
  max-width: 76rem;
  margin-top: 4rem;
}

.footer-link-item-container {
  min-width: 25rem;
  margin-bottom: 1.2rem;
}

@media (min-width: 501px) {
  .footer-link-item-container {
    display: inline-block;
  }
}

.footer-link-item {
  font-size: 1.5rem;
  text-decoration: none;
  color: #d7d7d7;
}

.footer-link-item:--focus-hover {
  color: #fff;
}

.footer-legal-text {
  margin-top: 3rem;
  font-size: 1.4rem;
  line-height: 1.6;
  color: #acacac;
}

@media (min-width: 500px) {
  .footer-link-item-container {
    display: inline-block;
  }
}

@media (max-width: 501px) {
  .footer-logo {
    display: block;
    margin: 0 auto;
  }

  .footer-link-item-container {
    display: block;
    text-align: center;
  }

  .footer-legal-text {
    text-align: center;
  }
}
